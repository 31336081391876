@import '../../../../scss/theme-bootstrap';

$main-menu-bottom-padding: 200px;
$gnav-animation: slide-in-#{$ldirection} 0.3s ease-in;
$gnav-mb-full-height: calc(#{$gnav-promo-mb-height} + #{$gnav-courtesy-height} + 1px);
$gnav-pc-sticky-height: calc(#{$gnav-courtesy-height} + #{$gnav-pc-nav-height} + 1px);
$gnav-pc-full-height: calc(#{$gnav-promo-pc-height} + #{$gnav-pc-sticky-height});
$close-icon-size: 17px;

.gnav-block {
  z-index: 1000;
  width: 100%;
  height: $gnav-mb-full-height;
  min-height: $gnav-mb-full-height;
  background-color: $color-white;
  position: relative;
  @media #{$cr19-large-up} {
    z-index: 9;
    height: $gnav-pc-full-height;
    min-height: $gnav-pc-full-height;
  }
  &.minimal-header {
    height: $gnav-courtesy-height;
    min-height: $gnav-courtesy-height;
  }
  .active-gnav &,
  .search-overlay-displayed & {
    height: $gnav-courtesy-height;
    min-height: $gnav-courtesy-height;
  }
  &__nav-container {
    border-bottom: 1px solid $color-grey;
  }
  &__inner {
    background: $color-white;
    position: fixed;
    transition: $gnav-ease-out-transition;
    width: 100%;
    z-index: 9;
    .gnav-block-hidden & {
      transform: translateY(-100%);
      opacity: 0;
    }
    .active-gnav &,
    .search-overlay-displayed & {
      opacity: 1;
      transform: none;
    }
  }
  &__promo-bar {
    transition: $gnav-ease-out-transition;
    height: $gnav-promo-mb-height;
    opacity: 1;
    @media #{$cr19-large-up} {
      height: $gnav-promo-pc-height;
    }
    .gnav-block-sticky-enabled &,
    .active-gnav & {
      height: 0;
      opacity: 0;
      visibility: hidden;
    }
  }
  &__courtesy-navigation-item-guest {
    @include courtesy-nav-separator;
  }
  &__courtesy-navigation-item-loyalty {
    @include courtesy-nav-separator;
  }
  &__courtesy-navigation-item-non-loyalty {
    @include courtesy-nav-separator;
  }
  &__courtesy-navigation-item,
  &-navigation-courtesy-item {
    display: none;
  }
  &__courtesy-navigation-item-loyalty,
  &-navigation-courtesy-item-loyalty {
    .elc-user-state-loyalty:not(.elc-user-state-anonymous) &,
    .is-loyalty-member & {
      display: block;
    }
  }
  &__courtesy-navigation-item-non-loyalty,
  &-navigation-courtesy-item-non-loyalty {
    .elc-user-state-logged-in:not(.elc-user-state-loyalty):not(.is-loyalty-member) & {
      display: block;
    }
  }
  &__courtesy-navigation-item-guest,
  &-navigation-courtesy-item-guest {
    .elc-user-state-anonymous & {
      display: block;
    }
  }
  &__courtesy-navigation-item-language-toggle,
  &-navigation-courtesy-item-language-toggle {
    @include text-link--style-2;
    display: inline-flex;
    text-decoration: none;
    .gnav-block__courtesy-navigation-item ~ &::before {
      @media #{$cr19-large-up} {
        content: '|';
        clear: both;
        display: inline-block;
        padding: 0 18px;
        color: $color-grey;
        font-size: 0.875rem;
      }
    }
    .gnav-language-toggle {
      &-cta {
        @include text-link--style-2;
        @media #{$cr19-large-up} {
          font-size: 0.875rem;
        }
      }
      .mp_lang {
        &::after {
          position: relative;
          content: '|';
          top: -1px;
          font-size: 0.875rem;
        }
        &:last-child::after {
          content: none;
        }
        .lang-abbr {
          text-transform: uppercase;
        }
        .lang-full {
          display: none;
        }
      }
    }
  }
  &-navigation-courtesy {
    gap: 12px;
    display: flex;
    flex-direction: column;
    border-top: 1px solid $color-grey;
    padding: 24px 0 40px;
    @media #{$cr19-large-up} {
      display: none;
    }
  }
  &__courtesy {
    &-hamburger-link svg {
      width: $close-icon-size;
      height: $close-icon-size;
    }
    height: $gnav-courtesy-height;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $color-white;
    @media #{$cr19-large-up} {
      height: $gnav-courtesy-height;
    }
    &-close {
      display: none;
    }
    &-section-links {
      display: none;
      @media #{$cr19-large-up} {
        display: block;
      }
    }
    &-utilities {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 27px;
      .active-gnav &,
      .search-overlay-displayed & {
        visibility: hidden;
        height: 0;
        @media #{$cr19-large-up} {
          visibility: visible;
          height: auto;
        }
      }
    }
    .gnav-block__courtesy-utilities-item {
      height: 24px;
      [role='article'].clearfix {
        overflow: visible;
      }
    }
    &-hamburger {
      @media #{$cr19-large-up} {
        display: none;
      }
    }
    &-search {
      background: url('#{$img_base_path}/icons/src/search-icon.svg') 0 center no-repeat;
      width: 28px;
      .elc-search-icon {
        background: none;
      }
      .elc-search-results-button {
        z-index: 2;
      }
    }
    &-section {
      @media #{$cr19-large-up} {
        display: flex;
        gap: 37px;
        margin-#{$rdirection}: -4px;
      }
    }
    &-navigation-list {
      @media #{$cr19-large-up} {
        display: flex;
      }
    }
    &-navigation-item.text-link--style-2,
    &-navigation-link.text-link--style-2 {
      @media #{$cr19-large-up} {
        font-size: 0.875rem;
      }
    }
  }
  &__courtesy-logo {
    @media #{$cr19-large-up} {
      padding-top: 10px;
    }
    .svg-icon-logo {
      height: 30px;
      width: 110px;
      @media #{$cr19-large-up} {
        height: 40px;
        width: 150px;
      }
    }
  }
  &__main-navigation {
    display: none;
    @media #{$cr19-large-up} {
      display: block;
    }
    &-link.text-link--style-2-bold,
    &-link-back.text-link--style-2-bold {
      font-size: 1.25rem;
      @media #{$cr19-large-up} {
        font-size: 1rem;
      }
      @media #{$cr19-typography-xlarge-up} {
        font-size: 1.125rem;
      }
    }
    &-link-back.button {
      display: flex;
      align-items: center;
      gap: 12px;
      padding-bottom: 30px;
      svg.svg-icon {
        width: 20px;
        height: 20px;
      }
      @media #{$cr19-large-up} {
        display: none;
      }
    }
    &-link {
      display: flex;
      justify-content: space-between;
      svg.svg-icon {
        width: 20px;
        height: 20px;
        @media #{$cr19-large-up} {
          display: none;
        }
      }
    }
    &-list {
      display: flex;
      flex-direction: column;
      gap: 12px;
      padding-top: 5px;
      @media #{$cr19-large-up} {
        flex-direction: row;
        justify-content: space-between;
        gap: 0;
        padding: 0;
        height: $gnav-pc-nav-height;
        align-items: center;
      }
    }

    &-layout {
      visibility: hidden;
      position: fixed;
      background-color: $color-white;
      z-index: 9;
      width: 100%;
      #{$ldirection}: 0;
      overflow-y: auto;
      opacity: 0;

      @media #{$cr19-large-up} {
        position: absolute;
        top: $gnav-pc-full-height;
        padding-top: 0;
        padding-bottom: 0;
        opacity: 1;
      }
      .search-overlay-displayed & {
        z-index: 1000;
        @media #{$cr19-large-up} {
          top: $gnav-pc-sticky-height;
        }
      }
      &-content {
        @media #{$cr19-large-up} {
          opacity: 0;
        }
      }

      &-content-close {
        display: none;
        &-label {
          @media #{$cr19-large-up} {
            margin-top: -24px;
          }
        }
      }
      [role='article'].clearfix {
        overflow: visible;
      }
    }
    &-link-back {
      display: flex;
      align-items: center;
      gap: 12px;
      padding-bottom: 30px;
      margin-#{$ldirection}: -5px;
      @media #{$cr19-large-up} {
        display: none;
      }
      span {
        padding-top: 5px;
      }
    }
  }
  &__navigation-item {
    &:empty {
      display: none;
    }
    @media #{$cr19-large-up} {
      height: calc(#{$gnav-pc-nav-height} + 1px);
      display: flex;
      align-items: center;
      padding: 0 10px;
      flex-grow: 1;
    }
    &:first-child {
      @media #{$cr19-large-up} {
        padding-#{$ldirection}: 0;
      }
    }
    &:last-child {
      @media #{$cr19-large-up} {
        padding-#{$rdirection}: 0;
        flex-grow: 0;
      }
    }
    &:hover {
      .gnav-block__main-navigation-link {
        @media #{$cr19-large-up} {
          text-decoration: underline;
          text-underline-offset: $default-underline-offset;
          transition-property: all;
          transition-delay: 0.2s;
        }
      }
      .gnav-block__main-navigation-layout {
        @media #{$cr19-large-up} {
          visibility: visible;
          opacity: 1;
          height: auto;
          padding-top: 30px;
          padding-bottom: 30px;
          transition-property: all;
          transition-delay: 0.2s;
        }
        &-content {
          @media #{$cr19-large-up} {
            opacity: 1;
            animation: fade 0.3s ease-in;
          }
        }

        .gnav-block-sticky-enabled & {
          @media #{$cr19-large-up} {
            position: fixed;
            width: 100%;
            top: $gnav-pc-sticky-height;
          }
        }
      }
    }
    label.gnav-block__main-navigation-link {
      @media #{$cr19-large-up} {
        pointer-events: none;
      }
    }
  }
  &__input-navigation:checked {
    & ~ .gnav-block__main-navigation-layout {
      visibility: visible;
      padding-bottom: 100px;
      top: $gnav-courtesy-height;
      height: 100%;
      opacity: 1;

      @media #{$cr19-large-up} {
        height: auto;
        top: $gnav-pc-full-height;
        padding-top: 30px;
        padding-bottom: 30px;
      }
      .gnav-block__main-navigation-layout-content-close {
        @media #{$cr19-large-up} {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          padding: 15px 0;
        }
      }
      .gnav-block-sticky-enabled & {
        @media #{$cr19-large-up} {
          top: $gnav-pc-sticky-height;
        }
      }
      .gnav-block__main-navigation-layout-content {
        @media #{$cr19-large-up} {
          opacity: 1;
          animation: fade 0.3s ease-in;
        }
      }
    }
    & ~ .gnav-block__main-navigation-link {
      display: none;
      @media #{$cr19-large-up} {
        display: block;
      }
    }
  }
  #gnav_mobile_input_hamburger_trigger:checked {
    & ~ .gnav-block__nav-container {
      .gnav-block__main-navigation {
        position: fixed;
        width: 100%;
        height: calc(100% - #{$gnav-courtesy-height});
        background-color: $color-white;
        z-index: 999;
        #{$ldirection}: 0;
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        animation: $gnav-animation;
        @media #{$cr19-medium-up} {
          padding: 0 44px;
        }
        @media #{$cr19-large-up} {
          position: absolute;
        }
      }

      .gnav-block__courtesy {
        z-index: 9999;
        width: 100%;
        #{$ldirection}: 0;
        #{$rdirection}: 0;
        height: $gnav-courtesy-height;
        animation: $gnav-animation;

        .gnav-block__courtesy-utilities-item {
          display: none;
        }
        .gnav-block__courtesy-close {
          display: block;
          .svg-icon {
            width: $close-icon-size;
            height: $close-icon-size;
          }
        }
      }
    }
    & ~ .gnav-block__promo-bar {
      display: none;
    }
  }
  input[tabindex='-1'] {
    position: absolute;
    #{$ldirection}: -9999px;
  }
  .svg-icon,
  .svgicon--bag {
    width: 24px;
    height: 24px;
    fill: $color-black;
  }
  .svg-icon-arrow {
    transform: rotate(180deg);
  }
  &__dark-overlay {
    @media #{$cr19-large-up} {
      background-color: $cr19-bg-dark-transparent;
      visibility: hidden;
      opacity: 0;
      position: fixed;
      width: 100%;
      #{$ldirection}: 0;
      top: 0;
      height: 100%;
      transition: visibility 0.7s, opacity 0.7s;
    }
    .active-gnav & {
      @media #{$cr19-large-up} {
        visibility: visible;
        opacity: 1;
        z-index: 9;
      }
    }
  }
}
// Search styles.
.sd-search-gnav-input-field {
  .elc-shade-swatches-wrapper {
    display: none;
  }
  .elc-search-suggestion-value {
    @media #{$cr19-large-up} {
      border: none;
    }
  }
  .elc-search-suggestion-wrapper {
    background: $color-white;
    padding: 0;
    @media #{$cr19-medium-up} {
      margin-#{$rdirection}: 20px;
    }
  }
  .elc-search-typeahead-title {
    @media #{$cr19-large-up} {
      padding-bottom: 0;
    }
  }
  .elc-search-results-wrapper {
    background: $color-white;
    display: block;
    @media #{$cr19-medium-up} {
      display: grid;
      grid-template-columns: 1fr 4fr;
    }
    .elc-search-suggestion-container {
      margin-bottom: 35px;
      .elc-search-suggestion-value {
        border: none;
      }
    }
    .message-cta-container {
      display: flex;
      justify-content: space-between;
      @media #{$cr19-medium-up} {
        justify-content: #{$ldirection};
      }
      .elc-search-results-cta {
        @include text-link--style-7;
        line-height: normal;
        margin-#{$ldirection}: 16px;
        // Overrides mixin styling
        @media #{$cr19-large-up} {
          line-height: normal;
        }
      }
    }
    h5.elc-heading--5 {
      text-align: #{$ldirection};
    }
    .elc-search-has-suggestions {
      grid-row: 1 / span 2;
      margin: 40px 0;
    }
    &:has(.elc-search-has-no-suggestions) {
      grid-template-columns: unset;
    }
    .elc-search-has-no-suggestions {
      margin-top: 20px;
    }
  }
  .elc-grid-item-product {
    padding: 0;
    overflow: hidden;
    @media #{$cr19-large-up} {
      padding: 8px;
    }
    &:first-of-type,
    &:nth-of-type(5n) {
      @media #{$cr19-large-up} {
        padding-#{$ldirection}: 0;
      }
    }
  }
  .elc-product-grid-wrapper {
    margin: 0;
  }
  .elc-search-enhanced-trending-terms-wrapper {
    margin-bottom: 35px;
  }
  .elc-search-enhanced-trending-title,
  .elc-search-typeahead-title {
    padding: 0 0 20px;
  }
  .elc-search-trending-term,
  .elc-search-suggestion-value {
    padding: 0 0 10px;
  }
  .elc-search-trending-term__link,
  .elc-search-typeahead-value {
    @include text-link--style-7;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  .elc-search-active-screen-wrapper {
    height: 100%;
    background: $color-black-6opacity;
    overflow: auto;
    visibility: visible;
    width: 100%;
    @media #{$cr19-large-up} {
      top: $gnav-promo-pc-height;
    }
    .gnav-block-sticky-enabled & {
      top: 0;
    }
    .active-gnav & {
      z-index: -1;
    }
    & > div {
      @media #{$cr19-large-up} {
        padding-#{$ldirection}: 85px;
        padding-#{$rdirection}: 85px;
      }
      @media #{$cr19-xxxlarge-up} {
        padding-#{$ldirection}: calc(50% - 875px);
        padding-#{$rdirection}: calc(50% - 875px);
      }
    }
  }
  .elc-search-enhanced-wrapper,
  .elc-search-results-wrapper {
    height: auto;
    min-height: calc(100% - 84px);
    padding: 16px 20px;
    @media #{$cr19-medium-up} {
      min-height: calc(100% - 104px);
      padding: 24px 20px;
    }
    @media #{$cr19-large-up} {
      min-height: 60%;
    }
  }
  .elc-search-gnav-box {
    display: flex;
    border: none;
    padding: 16px 20px;
    @media #{$cr19-medium-up} {
      padding: 24px 20px;
    }
    .elc-search-clear-button {
      @include text-body-text--small;
      #{$rdirection}: 105px;
      position: absolute;
      text-transform: capitalize;
      top: 28px;
      @media #{$cr19-medium-up} {
        #{$rdirection}: 110px;
        top: 37px;
      }
      @media #{$cr19-large-up} {
        #{$rdirection}: 130px;
      }
      @media #{$cr19-xxxlarge-up} {
        #{$rdirection}: calc(50% - 830px);
      }
    }
    .elc-search-button-icon {
      background: url('#{$img_base_path}/icons/src/search-icon.svg') 0 center no-repeat;
      height: 20px;
      position: absolute;
      #{$rdirection}: 75px;
      top: 30px;
      width: 20px;
      @media #{$cr19-medium-up} {
        #{$rdirection}: 80px;
        top: 37px;
      }
      @media #{$cr19-large-up} {
        #{$rdirection}: 100px;
      }
      @media #{$cr19-xxxlarge-up} {
        #{$rdirection}: calc(50% - 860px);
      }
    }
    &.voice-search-enabled {
      .elc-search-clear-button {
        #{$rdirection}: 145px;
        @media #{$cr19-medium-up} {
          #{$rdirection}: 110px;
        }
        @media #{$cr19-large-up} {
          #{$rdirection}: 130px;
        }
        @media #{$cr19-xxxlarge-up} {
          #{$rdirection}: calc(50% - 830px);
        }
      }
      .elc-search-button-icon {
        #{$rdirection}: 115px;
        @media #{$cr19-medium-up} {
          #{$rdirection}: 80px;
        }
        @media #{$cr19-large-up} {
          #{$rdirection}: 100px;
        }
        @media #{$cr19-xxxlarge-up} {
          #{$rdirection}: calc(50% - 860px);
        }
      }
    }
  }
  .elc-search-close-icon-wrapper {
    position: relative;
    order: 1;
    top: 0;
    #{$rdirection}: 0;
    width: 45px;
    span {
      display: inline-block;
    }
    @media #{$cr19-large-up} {
      position: absolute;
      #{$rdirection}: 35px;
      top: 37px;
    }
    @media #{$cr19-xxxlarge-up} {
      #{$rdirection}: calc(50% - 925px);
    }
    .elc-search-close-icon {
      height: 14px;
      width: 14px;
    }
  }
  input.elc-input-search-field {
    @include swap-direction(padding, 10px 90px 10px 10px);
    @include text-body-text;
    border: 1px solid $color-grey;
    width: calc(100% - 45px);
    &::placeholder {
      @include text-body-text;
      color: $color-input-gray;
    }
    &.voice-search-enabled {
      padding-#{$rdirection}: 125px;
      @media #{$cr19-medium-up} {
        padding-#{$rdirection}: 90px;
      }
    }
    &:placeholder-shown {
      padding-#{$rdirection}: 40px;
    }
    @media #{$cr19-large-up} {
      width: 100%;
    }
  }
  .elc-voice-search-wrapper {
    #{$rdirection}: 60px;
    top: 14px;
  }
  .elc-search-enhanced-trending-title,
  .elc-search-enhanced-grid-title,
  .elc-search-result-overlay-message,
  .elc-search-result-adjustment-message {
    @include text-body-text($font-family: $helvetica-bold-font-family);
    font-weight: 800;
  }
  .elc-search-enhanced-grid-title {
    margin-bottom: 20px;
  }
  .elc-product-brief {
    display: grid;
    grid-template-columns: 30% 70%;
    grid-template-rows: auto 1fr;
    @media #{$cr19-large-up} {
      display: block;
    }
    &-view {
      gap: 15px;
      grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
      @media #{$cr19-large-up} {
        gap: revert;
        grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
      }
    }
    .elc-product-badge-wrapper {
      display: none;
      @media #{$cr19-large-up} {
        display: block;
      }
    }
    .elc-product-image-section {
      grid-column: 1;
      grid-row: 1 / span 2;
      .elc-product-images-wrapper {
        min-height: unset;
      }
    }
    .elc-product-name-section,
    .elc-product-details-section {
      @include swap-direction(margin, 0 0 0 10px);
      grid-column: 2;
      height: auto;
      @media #{$cr19-large-up} {
        margin: 10px 0 0;
        min-height: 200px;
      }
      &:hover {
        padding-bottom: 0;
      }
    }
    .elc-product-name-section {
      grid-row: 1;
      .elc-product-display-name-wrapper {
        h2.elc-product-display-name-link {
          font-family: $helvetica-bold-font-family;
        }
      }
      .elc-product-prices-clickable-wrapper {
        .elc-product-prices-wrapper {
          .elc-price-formatted {
            font-size: 16px;
          }
        }
      }
    }
    .elc-product-details-section {
      grid-row: 2;
    }
    .elc-main-content-section {
      display: none;
    }
  }
}
// Cart styles.
.header-gnav-cart {
  .cart-confirm-wrapper__bottom-section {
    @media #{$cr19-large-up} {
      padding-bottom: 60px;
    }
  }
  .gnav-header-formatter__close {
    position: fixed;
    #{$rdirection}: 20px;
    z-index: 999999;
    margin-top: -40px;
    @media #{$cr19-large-up} {
      #{$rdirection}: 0;
      padding: 20px;
      top: 10px;
      margin-top: 0;
    }
  }
  .gnav-header-formatter__close-icon {
    width: $close-icon-size;
    height: $close-icon-size;
  }
  .header-gnav-cart__trigger {
    display: inline-block;
  }
  .gnav-header-formatter__sections-panel {
    background: $color-white;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: $gnav-courtesy-height;
    width: 100%;
    height: calc(100% - #{$gnav-courtesy-height});
    z-index: 99;
    #{$rdirection}: 0;
    transform: translateX(100%);
    transition: $gnav-ease-out-transition;
    visibility: hidden;
    @media #{$cr19-large-up} {
      top: 0;
      height: 100%;
      width: 534px;
    }
    .active-gnav & {
      position: fixed;
      display: flex;
      flex-direction: column;
      visibility: visible;
      transform: translateX(0);
      @media #{$cr19-large-up} {
        width: 534px;
      }
    }
    .header-gnav-cart__content-wrapper {
      overflow-y: auto;
    }
  }
  .cart-confirm-wrapper__top-section {
    display: none;
    @media #{$cr19-large-up} {
      display: block;
    }
  }
}
.header-gnav__wrapper {
  .vto-active:not(.active-gnav) & {
    display: none;
    @media #{$cr19-medium-up} {
      display: block;
    }
  }
  [role='article'].clearfix:first-child {
    overflow: visible;
    @media #{$cr19-large-up} {
      overflow: hidden;
    }
  }
}

// Override on body for shades sidebar when active
body {
  &.product-shades-sidebar-active {
    overflow: hidden;
  }
  // Fixes cart overlay when shades slide-out is active.
  &.active-gnav {
    .gnav-block {
      z-index: 99999;
      position: fixed;
    }
  }
  #top {
    height: revert-layer;
  }
}
